.nav{
    display: flex;
    align-items: center;
    width: 90%;
    margin: auto;
    justify-content: space-between;
    padding: 20px 80px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.06);
    
   
}

.logo-nav {
    justify-content: space-between;
    display: flex;
    padding-right:2rem;
    background-color: white;
    align-items: center;
    border-style: none;
    border: none;
    border-width: none;
    color: #906272;

}

.nav-color-change{
    
    background-color: white;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.06);
    margin-right: 3rem;
    
}
.nav-color-change .logo-nav {
    
    background-color: white;
   
    margin-right: 4rem;
    
}
#navbar{
    display: flex;
    align-items: center;
    justify-content: center;
}
#navbar li{
    padding: 0 20px;
    position: relative;
}
#navbar li a{
    text-decoration: none;
    font-size: 2rem;
    color: #906272;
    transition: 0.3s ease-in-out;
}

#navbar li a:hover  {
    color: palevioletred;
}
#navbar li a:focus  {
    color: palevioletred;
}
#navbar li a:hover::after
{
    content: "";
    width: 30%;
    height: 2px;
    background: rgb(128, 197, 240);
    position: absolute;
    bottom: -4px;
    left: 20px;
}

#mobile i {
    color: #906272;
}

#mobile{
    display: none;
}

.logo{
    color: white;
}


@media screen and (max-width:760px) {
    #navbar {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            position:fixed;
            top: 90px;
            right: -300px;
            width: 180px;
            height: 100vh;
            background-color: #e6c9d3;
            box-shadow: 0 40px 60px rgba(0, 0,0.1);
            padding: 40px 0 0 10px;
            transition: 0.3s ease-in-out;
        }
    #navbar.active{
        right:0px;
       
         }

    #navbar li{
        margin-bottom: 25px;
    }
    #mobile {
            display: block;
        }
    #mobile i{
        font-size: 24px;
        cursor: pointer;
    }

.nav {
    display: flex;
    align-items: center;
    width: 100%;
    
    justify-content: space-between;
    padding: 20px 80px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.06);

    }

        .nav-color-change nav{
            width: 100%;
        }
}