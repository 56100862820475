.item{
    min-height: 40rem;
    min-width: 30rem;
    padding: 40px;
}

.item img {
    width: 100%;
    height: 100%;
    border-radius: 2rem;
    pointer-events: none;
}

.inner-carousel {
    display: flex;
    
}
.carousel{
    margin-top: 6rem;
    cursor: grab;
    overflow: hidden;
    width: 90%;
   
}

.h1-gallery{
    text-align: center;
    margin-top: 3rem;
    color: burlywood;
    font-size: 5rem;
   
}