.video-responsive{
    width: 560px;
        /* you have to have a size or this method doesn't work */
        height: 315px;
        /* think about making these max-width instead - might give you some more responsiveness */
    
       
        /* positions out of the flow, but according to the nearest parent */
        top: 0;
        right: 0;
        /* confuse it i guess */
        bottom: 0;
        left: 0;
        margin: auto;
        /* make em equal */
        margin-bottom: 19rem;
}

.video-container {
    position: relative;
}

.h1-reel-style{
    text-align: center;
    margin-top: 7rem;
    font-size: 4rem;
    margin-bottom: 2rem;
    
}